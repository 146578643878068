.ant-tabs-content {
  height: 100%;
}

.ant-tabs-tabpane {
  height: 100%;
}

.ant-tab-with-overflow {
  .ant-tabs-content-holder {
    overflow-y: auto;
  }
}
