.dark-select {
  /*
  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: red;
  }
   */
  .ant-select {
    color: white;

    .ant-select-selector {
      background-color: #262626;
      border: 1px dashed #d9d9dd;
    }
  }
}

.ng-select .ng-select-container {
  min-height: 0;
  height: 25px;
}
.ng-select .ng-select-container .ng-value-container .ng-value {
  font-size: 13px;
}

.input-wrapper-select-width {
  display: flex;
  width: 100%;

  .ant-select {
    width: 100%;
  }
}
