.sticky-header-col {
  position: sticky !important;
  left: 0;
  top: 0;
  z-index: 2;
  background: #fff !important;
  box-shadow: 1px 1px 2px -1px rgba(0, 0, 0, 0.75);
}

.with-sticky-header {
  thead {
    top: 0;
    z-index: 2;
    position: sticky;
  }
}

.table--center-all-text {
  .ant-table-cell {
    text-align: center;
    align-items: center;
  }
  .ant-input-number-input {
    text-align: center;
  }
  td {
    text-align: center;
  }
  th {
    text-align: center;
  }
}

.table--documents {
  border-radius: 8px;

  .ant-table-thead > tr {
    height: 52px;

    th {
      background: var(--white-on-white-20, #f5f5f5);
      color: var(--white-on-white-100, #000);
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;

      &::before {
        width: 0 !important;
      }

      &.text-gray {
        color: #626a6a;
      }
    }
  }

  .ant-table-cell {
    color: var(--white-on-white-100, #000);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
  }

  .ant-table-pagination ul {
    display: flex;
    gap: 8px;
  }

  .ant-pagination-item {
    color: #36a8a0;
    border: 1px solid var(--brand-green-brand-green, #36a8a0) !important;
    background: var(--brand-green-on-brand-green-100, #FFF) !important;

    a {
      color: #36a8a0;
    }
  }

  .ant-pagination-item-active {
    color: #fff;
    background: var(--brand-green-brand-green, #36a8a0) !important;

    a {
      color: #fff;
      background: var(--brand-green-brand-green, #36a8a0);
    }
  }

  .sticky-header-col {
    position: sticky !important;
    left: 0;
    top: 0;
    z-index: 2;
    background: #f5f5f5 !important;
    box-shadow: none;
  }
}
