.ant-btn {
  border-radius: 4px;
}

.rais-btn--default {
  color: #36A8A0; border-color: #D4E2E1; display: flex; justify-content: center; align-items: center; gap: 4px
}

.ant-btn.btn--flat {
  box-shadow: none !important;
}

.ant-btn.bnt--borderless {
  border: none !important;
}

.ant-btn.btn--borderless {
  border: none !important;
}

.ant-btn.btn--outline {
  background: none;
}

.ant-btn-primary.btn--outline {
  color: #00a8a0;
}

.ant-btn-primary.btn--outline:hover {
  color: #00a8a0;
}

.rais-btn--flex {
  display: flex;
  gap: 4px;
  align-items: center;
}
